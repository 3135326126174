import "./jquery.serializejson.min";
import jQuery from "jquery";
import Inputmask from "inputmask";
import "./intlTelInput-jquery.min";

console.log("loaded main.js")
$(document).find("input.intl-tel").each(function () {
  console.log("input.intl-tel")
  var self = $(this);
  var val = self.val();
  if (val[0] === "7") {
    self.val("+" + val);
  }
  console.log("input.intl-tel", self);
  self.intlTelInput({
    formatOnDisplay: false,
    customPlaceholder: function (
      selectedCountryPlaceholder,
      selectedCountryData,
    ) {
      //console.log(selectedCountryPlaceholder.replaceAll(/[0-9]/g, '0'), selectedCountryData);
      if (selectedCountryData.iso2 === "ru") {
        selectedCountryPlaceholder = selectedCountryPlaceholder.replace(
          " 912 ",
          " (912) ",
        );
      }
      selectedCountryPlaceholder = selectedCountryPlaceholder.replace(
        "+" + selectedCountryData.dialCode,
        " ",
      );
      Inputmask(
        "+" +
        selectedCountryData.dialCode.replace("9", "\\9") +
        " " +
        selectedCountryPlaceholder.replaceAll(/[0-9]/g, "9"),
        {
          placeholder:
            "+" +
            selectedCountryData.dialCode.replace("9", "\\9") +
            " " +
            selectedCountryPlaceholder
              .replaceAll(/[0-9]/g, "_")
              .replaceAll(" ", "-"),

          clearMaskOnLostFocus: true,
          showMaskOnHover: false,
          positionCaretOnClick: "radixFocus",
        },
      ).mask(self[0]);
      return (
        "+" +
        selectedCountryData.dialCode.replace("9", "9") +
        " " +
        selectedCountryPlaceholder.replaceAll(/[0-9]/g, "9")
      );
    },
    nationalMode: false,
    /*
    onlyCountries: ["al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk",
                    "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie", "it", "lv",
                    "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro",
                    "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "gb"]*/
    excludeCountries: [
      "af",
      "al",
      "dz",
      "as",
      "ad",
      "ao",
      "ai",
      "ag",
      "aw",
      "ac",
      "bs",
      "bh",
      "bd",
      "bb",
      "bz",
      "bj",
      "bm",
      "bt",
      "bo",
      "ba",
      "bw",
      "io",
      "vg",
      "bn",
      "bf",
      "bi",
      "kh",
      "cm",
      "cv",
      "bq",
      "ky",
      "cf",
      "td",
      "cx",
      "cc",
      "km",
      "cd",
      "cg",
      "ck",
      "cr",
      "ci",
      "cu",
      "cw",
      "dj",
      "dm",
      "do",
      "ec",
      "eg",
      "sv",
      "gq",
      "er",
      "ee",
      "sz",
      "et",
      "fk",
      "fo",
      "fj",
      "fi",
      "gf",
      "pf",
      "ga",
      "gm",
      "gh",
      "gi",
      "gl",
      "gd",
      "gp",
      "gu",
      "gt",
      "gg",
      "gn",
      "gw",
      "gy",
      "ht",
      "hn",
      "ir",
      "iq",
      "im",
      "jm",
      "je",
      "jo",
      "ke",
      "xk",
      "kw",
      "kg",
      "la",
      "lb",
      "ls",
      "lr",
      "ly",
      "li",
      "mk",
      "mg",
      "mw",
      "my",
      "ml",
      "mh",
      "mq",
      "mr",
      "mu",
      "yt",
      "fm",
      "md",
      "mn",
      "ms",
      "ma",
      "mz",
      "mm",
      "na",
      "nr",
      "np",
      "nc",
      "ni",
      "ne",
      "ng",
      "nu",
      "nf",
      "kp",
      "mp",
      "om",
      "pk",
      "pw",
      "ps",
      "pa",
      "pg",
      "py",
      "pe",
      "ph",
      "pr",
      "re",
      "ro",
      "rw",
      "bl",
      "sh",
      "kn",
      "lc",
      "mf",
      "pm",
      "vc",
      "ws",
      "sm",
      "st",
      "sa",
      "sn",
      "sc",
      "sl",
      "sx",
      "sk",
      "sb",
      "so",
      "za",
      "ss",
      "lk",
      "sd",
      "sr",
      "sj",
      "sy",
      "tj",
      "tz",
      "tl",
      "tg",
      "tk",
      "to",
      "tt",
      "tn",
      "tm",
      "tc",
      "tv",
      "vi",
      "ug",
      "uy",
      "vu",
      "va",
      "ve",
      "vn",
      "wf",
      "ye",
      "zm",
      "zw",
      "ax",
    ],
    placeholderNumberType: "MOBILE",
    preferredCountries: ["ru"],
    separateDialCode: false,
    utilsScript: "/assets/js/intlTelInput-utils.js",
  });
});